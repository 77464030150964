<template>
  <form @submit.prevent="saveRegisteredKeeper" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name <span class="text-red-400">*</span></label>
      <input v-model="registeredKeeper.name" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Name..." />
    </div>
    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Email</label>
      <input v-model="registeredKeeper.email" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Email..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Telephone</label>
      <input v-model="registeredKeeper.telephone" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Telephone..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Mobile</label>
      <input v-model="registeredKeeper.mobile" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Mobile..." />
    </div> -->
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address <span class="text-red-400">*</span></label>
      <input v-model="registeredKeeper.address1" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Address..." />
    </div>
    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 2</label>
      <input v-model="registeredKeeper.address2" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Purpose..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 3</label>
      <input v-model="registeredKeeper.address3" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Address2..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">City</label>
      <input v-model="registeredKeeper.city" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="City..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">County</label>
      <input v-model="registeredKeeper.county" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="County..." />
    </div> -->
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Postcode <span class="text-red-400">*</span></label>
      <input v-model="registeredKeeper.postcode" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Postcode..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Country <span class="text-red-400">*</span></label>
      <div class="inline-block relative w-full">
        <select v-model="registeredKeeper.country" class="bge-input bge-select rounded">
          <optgroup label="Frequently Used">
            <option value="United Kingdom">United Kingdom</option>
            <option value="Kenya">Kenya</option>
            <option value="England">England</option>
            <option value="Scotland">Scotland</option>
            <option value="Wales">Wales</option>
            <option value="Northern Ireland">Northern Ireland</option>
            <option value="Republic of Ireland">Republic of Ireland</option>
          </optgroup>
          <optgroup label="All Countries">
            <template v-for="(country, i) in countries">
              <option :key="i" :value="country">{{ country }}</option>
            </template>
          </optgroup>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>

    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveRegisteredKeeper"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Registered Keeper</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
import { COUNTRIES } from "@/utils/constants";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditRegisteredKeeper",
  components: { IsAuthorized },
  props: {
    registeredKeeper: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      countries: [],
    };
  },
  mounted() {
    this.countries = COUNTRIES;
  },
  methods: {
    saveRegisteredKeeper: function() {
      this.$emit("complete", this.registeredKeeper);
    },
    deleteRegisteredKeeper: function() {
      this.$emit("delete", this.registeredKeeper);
    },
  },
};
</script>
